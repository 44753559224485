import React, { useContext, useState } from "react";
import { ThemeContext } from "../../store/themeContext/themeContext";
import useStyles from "./navbarStyles";
import { Logo, Moon, Sun } from "../../components/icons/icons";
import { Link } from "react-router-dom";
import { ReactComponent as FairDriveLogotypeWhiteSVG } from "../../media/images/logotype-white.svg";
import { ReactComponent as FairDriveLogotypeDarkSVG } from "../../media/images/logotype-dark.svg";

export interface Props {}

function Navbar(props: Props) {
  const { theme, toggleTheme } = useContext(ThemeContext);
  const [open, setOpen] = useState(false);

  const classes = useStyles({ ...props, ...theme });

  return (
    <header className={`${classes.Navbar} ${open ? "open" : ""}`}>
      <Link to="/" className={classes.logo}>
        {theme.name === "dark" ? (
          <FairDriveLogotypeWhiteSVG />
        ) : (
          <FairDriveLogotypeDarkSVG />
        )}
      </Link>
      <nav className={`${classes.navMenu} ${open ? "open" : ""}`}>
        <a
          className={`${classes.navLink} ${classes.secondary}`}
          target="_blank"
          rel="noopener noreferrer"
          href="https://fairdataprotocol.bzz.link"
        >
          Develop On Web3
        </a>
        <a
          className={`${classes.navLink} ${classes.secondary}`}
          target="_blank"
          rel="noopener noreferrer"
          href="https://my.ethswarm.org/grants"
        >
          Apply For Grant
        </a>
        <a
          className={`${classes.navLink} ${classes.secondary}`}
          target="_blank"
          rel="noopener noreferrer"
          href="https://gitcoin.co/fairdatasociety/bounties"
        >
          Check Open Bounties
        </a>
        <div
          onClick={toggleTheme}
          className={`${classes.navLink} ${classes.themeTrigger} ${
            open ? "open" : ""
          }`}
        >
          {theme.name === "light" ? (
            <Moon className={classes.themeIcon} />
          ) : (
            <Sun className={classes.themeIcon} />
          )}
        </div>
        <a
          className={`${classes.actionButton}`}
          target="_blank"
          rel="noopener noreferrer"
          href="https://fairdatasociety.github.io/fairdrive-desktop-app/"
        >
          Try Desktop App
        </a>
        <a
          className={`${classes.actionButton}`}
          href="https://app.fairdrive.io/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Try Web App
        </a>
      </nav>
      <div className={classes.mobileElementsWrapper}>
        <div onClick={toggleTheme} className={`${classes.mobileThemeTrigger}`}>
          {theme.name === "light" ? (
            <Moon className={classes.themeIcon} />
          ) : (
            <Sun className={classes.themeIcon} />
          )}
        </div>
        <div className={classes.navIcon} onClick={() => setOpen(!open)}>
          <span className={`${classes.bar} ${open ? "open" : ""}`}></span>
          <span className={`${classes.bar} ${open ? "open" : ""}`}></span>
        </div>
      </div>
    </header>
  );
}

export default React.memo(Navbar);
