import React from "react";
import { useTheme } from "../../store/themeContext/themeContext";
import useStyles from "./overviewStyles";
import FDScreenshot from "../../media/images/fairdrive-overview.png";

import MobileOverlay from "../mobileOverlay/mobileOverlay";

export interface Props {
  // isAlertShow: boolean;
}

function Overview(props: Props) {
  const { theme } = useTheme();

  const classes = useStyles({ ...props, ...theme });

  return (
    <div className={classes.Overview}>
      <div className={classes.demoSection}>
        <p className={classes.headingSmall}>introducing fairdrive</p>
        <p className={classes.headingBig}>
          Where innovation, <span>interoperability</span> and{" "}
          <span>decentralization</span> unite in the name of
          <span> fair data.</span>
        </p>
        <p className={classes.paragraphBold}>
          Fairdrive is a community-driven initiative with the mission to empower
          freedom.
        </p>
        <p className={classes.paragraph}>
          By enabling decentralized storage, developers can create and build
          interoperable, decentralized and open-sourced dApps so users can
          reclaim their privacy, own their data and control their digital
          identity.
        </p>
        {/* <div className={classes.buttonBar}>
          <p className={classes.buttonParagraph}>
            Check out a quick how to set up Fairdrive:
          </p>
          <a
            className={classes.link}
            target="_blank"
            rel="noopener noreferrer"
            //TODO: Insert legit demo link
            href="https://www.youtube.com/watch?v=8NZrlbFIwdc"
          >
            Watch demo setup
          </a>
        </div> */}
      </div>
      <div className={classes.imageContainer}>
        <img
          alt="screen shot demo"
          className={classes.image}
          src={FDScreenshot}
        />
      </div>
      {/* {props.isAlertShow && <MobileOverlay />} */}
    </div>
  );
}

export default React.memo(Overview);
