// weight, size, family
export const typography = {
	h1: '700 12rem var(--font1)',
	h2: '700 8rem var(--font1)',
	h3: '400 6rem var(--font1)',
	h4: '400 4rem var(--font1)',
	h5: '600 3rem var(--font1)',
	h6: '500 2rem var(--font1)',
	subtitleLarge: '500 2rem var(--font1)',
	subtitleSmall: '400 1.75rem var(--font1)',
	body1: '400 2rem var(--font1)',
	body2: '500 2rem var(--font1)',
	body3: '600 2rem var(--font1)',
	caption1: '400 1.6rem var(--font1)',
	caption2: '500 1.6rem var(--font1)',
	caption3: '600 1.6rem var(--font1)',
	bigNumbers: '400 3.75rem var(--font2)',
	mediumNumbers: '400 3rem var(--font2)',
	smallNumbers: '400 2.25rem var(--font2)',
	bodyNumbers: '400 1.75 var(--font2)',
	tinyNumbers: '400 1.5rem var(--font2)',
	input: '400 2rem var(--font1)',
	error: '400 1.75rem var(--font1)',
	highlight: '700 1.5rem var(--font1)',
};

export interface Typography {
	h1: string;
	h2: string;
	h3: string;
	h4: string;
	h5: string;
	h6: string;
	subtitleLarge: string;
	subtitleSmall: string;
	body1: string;
	body2: string;
	body3: string;
	caption1: string;
	caption2: string;
	caption3: string;
	bigNumbers: string;
	mediumNumbers: string;
	smallNumbers: string;
	bodyNumbers: string;
	tinyNumbers: string;
	input: string;
	error: string;
	highlight: string;
}
